<!DOCTYPE html>
<!-- ==============================
	Project:        Metronic "Acecv" Frontend Freebie - Responsive HTML Template Based On Twitter Bootstrap 3.3.4
	Version:        1.0
	Author:         KeenThemes
	Primary use:    Corporate, Business Themes.
	Email:          support@keenthemes.com
	Follow:         http://www.twitter.com/keenthemes
	Like:           http://www.facebook.com/keenthemes
	Website:        http://www.keenthemes.com
	Premium:        Premium Metronic Admin Theme: http://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes
================================== -->
<ej-splash-screen *ngIf="showSplashScreen"></ej-splash-screen>

<html lang="en" class="no-js" *ngIf="!showSplashScreen">
<!-- BEGIN HEAD -->

<head>
    <meta charset="utf-8" />
    <title>eduJournal | Home</title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta content="" name="description" />
    <meta content="" name="author" />

    <!-- GLOBAL MANDATORY STYLES -->
    <link href="https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700" rel="stylesheet" type="text/css">
    <link href='https://fonts.googleapis.com/css?family=Caveat' rel='stylesheet'>
    <!-- <link href="../../assets/vendors/global/fonts/simple-line-icons/css/simple-line-icons.css" rel="stylesheet" type="text/css" /> -->
    <!-- <link href="../../assets/vendors/general/bootstrap/dist/css/bootstrap.min.css" rel="stylesheet" type="text/css" /> -->

    <!-- Favicon -->
    <link rel="shortcut icon" href="favicon.ico" />
</head>
<!-- END HEAD -->
<!-- BODY -->


<body id="body" data-spy="scroll" data-target=".header" *ngIf="showMyHome">

    <!--========== HEADER ==========-->
    <header class="header navbar-fixed-top">
        <!-- Navbar -->
        <nav class="navbar" role="navigation">
            <div class="c1">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="menu-container js_nav-item">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".nav-collapse">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="toggle-icon"></span>
                    </button>

                    <!-- Logo -->
                    <div class="logo" *ngIf="!lodingTenantInfo">
                        <a class="logo-wrap" href="javascript:;" (click)="headerLogoClick()">
                            <img class="logo-img1" [src]="tenantLogoSrc" alt="eduJournal" style="max-height: 70px;">
                        </a>
                    </div>
                    <!-- End Logo -->
                </div>

                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse nav-collapse" *ngIf="!isAdmin">
                    <div class="menu-container">
                        <ul class="nav navbar-nav-right">
                            <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover"
                                    href="javascript:void(0)" (click)="scrollToElement('body')">Home</a></li>
                            <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover"
                                    href="javascript:void(0)" (click)="scrollToElement('work')">Courses</a></li>
                            <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover"
                                    href="javascript:void(0)" (click)="scrollToElement('about')">About</a></li>
                            <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover"
                                    href="javascript:void(0)" (click)="scrollToElement('contact')">Contact</a></li>
                            <li class="js_nav-item nav-item">
                                <a class="nav-item-child nav-item-hover d-none" href="javascript:void(0)"
                                    *ngIf="!isLoggedIn" (click)="login()">Admin Sign In</a>
                                <a class="nav-item-child nav-item-hover" href="javascript:void(0)" *ngIf="!isLoggedIn"
                                    (click)="notebookLogin()">Sign In</a>
                                <a class="nav-item-child nav-item-hover" href="javascript:void(0)" *ngIf="isLoggedIn"
                                    (click)="gotoApplication()">Go to Application</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- End Navbar Collapse -->
            </div>
        </nav>
        <!-- Navbar -->
    </header>
    <!--========== END HEADER ==========-->
    <!--========== SLIDER ==========-->
    <div class="promo-block" *ngIf="loadSchoolInfo"
        style="padding-top: 0px; background-repeat: no-repeat; background-size: 100%;" id="divBanner"
        [ngStyle]="{'background-image': (homeViewModel?.schoolProfileViewModel?.bannerImageDocumentViewModel?.currentDocumentVerification?.status == 2 && homeViewModel?.schoolProfileViewModel?.bannerImageDocumentViewModel?.isDeleted == false)
            ? 'url(' + homeViewModel?.schoolProfileViewModel?.schoolProfile?.bannerImageDocument?.relativePath + ')' : 'url(../../../../assets/media/img/MVB-Image_005.png)' }">
        <div class="container" style="padding-top: 270px;">
            <div class="row">
                <div class="col-sm-6 sm-margin-b-10" *ngIf="!lodingTenantInfo">
                    <div class="margin-b-20">
                        <h1 class="promo-block-title mx-auto mx-md-0" style="width: min-content;">
                            {{homeViewModel?.displayHome ?
                            homeViewModel?.schoolProfileViewModel.schoolProfile?.schoolname : 'Your School Name will be
                            shown here'}}
                        </h1>
                        <p class="promo-block-text bg-success px-3 rounded text-white mx-auto mx-md-0"
                            style="width: fit-content">
                            {{homeViewModel?.displayHome ?
                            homeViewModel?.schoolProfileViewModel.schoolProfile?.schoolTitle : 'Your School Tagline will
                            be shown here'}}
                        </p>
                    </div>
                    <ul class="list-inline">
                        <li *ngIf="homeViewModel?.schoolProfileViewModel.schoolProfile?.facebookURL">
                            <a (click)="socialMediaClickEvent(homeViewModel?.schoolProfileViewModel.schoolProfile?.facebookURL)"
                                href="javascript:void(0)" class="social-icons"><i class="icon-social-facebook"></i></a>
                        </li>
                        <li *ngIf="homeViewModel?.schoolProfileViewModel.schoolProfile?.twitterURL">
                            <a (click)="socialMediaClickEvent(homeViewModel?.schoolProfileViewModel.schoolProfile?.twitterURL)"
                                href="javascript:void(0)" class="social-icons"><i class="icon-social-twitter"></i></a>
                        </li>
                        <li *ngIf="homeViewModel?.schoolProfileViewModel.schoolProfile?.linkedInURL">
                            <a (click)="socialMediaClickEvent(homeViewModel?.schoolProfileViewModel.schoolProfile?.linkedInURL)"
                                href="javascript:void(0)" class="social-icons"><i class="icon-social-linkedin"></i></a>
                        </li>
                        <li *ngIf="homeViewModel?.schoolProfileViewModel.schoolProfile?.otherSocialMediaURL">
                            <a (click)="socialMediaClickEvent(homeViewModel?.schoolProfileViewModel.schoolProfile?.otherSocialMediaURL)"
                                href="javascript:void(0)" class="social-icons"><i class="fa fa-globe"></i></a>
                        </li>

                    </ul>
                </div>
                <div class="col-sm-6">
                    <div class="promo-block-img-wrap">
                        <!--<img class="promo-block-img img-responsive" src="assets/demo/default/media/img/websiteimages/970x647/01.jpg" align="Avatar">-->
                    </div>
                </div>
            </div>
            <!--// end row -->
        </div>
    </div>
    <!--========== SLIDER ==========-->
    <!--========== PAGE LAYOUT ==========-->
    <!-- Work -->
    <div id="work">
        <div class="container content-lg pb-0" *ngIf="!showNotebookCourse">
            <div class="row margin-b-20">
                <div class="col-sm-6">
                    <h2>Latest Courses</h2>
                    <p *ngIf="!homeViewModel?.displayCourse && !lodingTenantInfo">Your Courses will be shown here</p>
                </div>
            </div>
            <!--// end row -->

            <div class="row" *ngIf="!lodingTenantInfo">
                <ng-container *ngFor="let item of homeViewModel?.trainingProgramViewModels; let i = index">
                    <div class="col-sm-4 sm-margin-b-50" style="margin-bottom:20px;" *ngIf="i < 3">
                        <div class="margin-b-20">
                            <div class="wow zoomIn" data-wow-duration=".3" data-wow-delay=".1s">
                                <div style="height: 193px;overflow: hidden;">
                                    <a href="javascript:void(0)" (click)="courseNav(item.id, item.wpPostUrl)">
                                        <img class="img-responsive"
                                            src="{{(item.courseImageName!=null && item.courseImageName!='') ? (item.courseImageRelativePath + '\\360x240\\' + item.courseImageName) : 'assets/media/logos/Default_Image_Course.png'}}"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <h4><a href="javascript:void(0)" (click)="courseNav(item.id, item.wpPostUrl)">{{item.name}}</a>
                            <span class="text-uppercase margin-l-20"></span>
                        </h4>
                        <p class="avoid-text-exceed">{{item.description}}
                            <a *ngIf="item.description?.includes('...')" href="javascript:void(0)"
                                (click)="courseNav(item.id, item.wpPostUrl)">
                                <span class="span-link">Read more</span>
                            </a>
                        </p>
                    </div>
                </ng-container>
            </div>
            <div class="row" style="margin-bottom:20px;">
                <ng-container *ngFor="let item of homeViewModel?.trainingProgramViewModels; let i = index">
                    <div class="col-sm-4 sm-margin-b-50" style="margin-bottom:20px;" *ngIf="i > 2 && i < 6">
                        <div class="margin-b-20">
                            <div class="wow zoomIn" data-wow-duration=".3" data-wow-delay=".1s">
                                <div style="height: 193px;overflow: hidden;">
                                    <a href="javascript:void(0)" (click)="courseNav(item.id, item.wpPostUrl)">
                                        <img class="img-responsive"
                                            src="{{(item.courseImageName!=null && item.courseImageName!='') ? (item.courseImageRelativePath + '\\360x240\\' + item.courseImageName) : 'assets/media/logos/Default_Image_Course.png'}}"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <h4><a href="javascript:void(0)" (click)="courseNav(item.id, item.wpPostUrl)">{{item.name}}</a>
                            <span class="text-uppercase margin-l-20"></span>
                        </h4>
                        <p class="avoid-text-exceed">{{item.description}}
                            <a *ngIf="item.description?.includes('...')" href="javascript:void(0)"
                                (click)="courseNav(item.id, item.wpPostUrl)">
                                <span class="span-link">Read more</span>
                            </a>
                        </p>
                    </div>
                </ng-container>
            </div>
            <!--// end row -->
        </div>

        <div class="container content-md" *ngIf="showNotebookCourse">
            <div class="row mb-5 d-flex flex-column justify-content-center align-items-center">
                <div class="px-2 mb-5" id="course-title"
                    style="font-weight:600;text-align:center;line-height:1.2;font-family:'Poppins'">
                    <span style="font-family: 'Caveat';font-size:4rem;color:#333;">Materials for your</span> exams
                    <br />
                    are consolidated in a single location
                </div>
                <div class="px-2" style="font-size: 1.65rem;font-weight:500;text-align:center;">
                    Prepare for the exam with content specifically tailored to the syllabus for guaranteed readiness.
                </div>
                <div class="px-2" style="font-size: 1.65rem;font-weight:500;text-align:center;">Click on below listed
                    class you want
                    to register.</div>
            </div>
            <div class="ej-nb-course">
                <div class="row">
                    <div *ngFor="let g of notebookCourse;" class="col-6 col-md-3 grade-card p-2">
                        <div class="p-2 m-2 shadow-sm d-flex grade-card-container"
                            (click)="redirectToNotebook(notebookSignUpUrl,g)">
                            <img class="mx-2" style="width: 64px;"
                                src="https://notebook.edujournal.com/assets/media/images/Class/Class{{g.name}}.png">
                            <div class="d-flex flex-column justify-content-center align-items-center w-100">
                                <div class=""
                                    style="font-size: 1.8rem;color:#999999;font-weight:500;line-height:1.6rem">
                                    {{g.name}}</div>
                                <div class="" style="font-size: 1.3rem;color:#a6a7aa;font-weight:400;">Class</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="about">
        <div class="container content-md">
            <div class="row">
                <div class="col-sm-12">
                    <div class="section-seperator">
                        <div class="">
                            <div class="margin-b-30">
                                <h2>About Us</h2>
                                <p class="font-weight-bold" style="font-size: 1.35rem;">Mission Statement for MindVault
                                    Brainiacs Academy Tuition
                                </p>
                                <p style="font-size: 1.25rem;">At MindVault Brainiacs Academy, our mission is to empower
                                    students with innovative
                                    and engaging learning experiences that inspire curiosity, critical thinking, and a
                                    lifelong love of knowledge. We are dedicated to providing personalized education
                                    that enhances academic performance, nurtures creativity, and fosters holistic
                                    growth, preparing every student to excel in school and beyond.</p>
                                <!--  <p *ngIf="homeViewModel?.displayAboutUs"
                                    [innerHTML]="homeViewModel?.schoolProfileViewModel.schoolProfile?.aboutUs">
                                </p>
                               <p *ngIf="!homeViewModel?.displayAboutUs && !lodingTenantInfo">Your About Us will be
                                    shown here</p>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End About -->
    <!-- Contact -->
    <div id="contact">
        <div class="bg-color-sky-light">
            <div class="container content-lg">
                <div class="row margin-b-20">
                    <div class="col-sm-6">
                        <h2>Contact Us</h2>
                        <p *ngIf="!homeViewModel?.displayContactUs && !lodingTenantInfo">Your Contact Us will be shown
                            here
                        </p>
                    </div>
                </div>
                <!--// end row -->
                <div class="row" *ngIf="homeViewModel?.displayContactUs">
                    <div class="col-md-3 col-xs-6 md-margin-b-30">
                        <h4>Location</h4>
                        <a *ngIf="homeViewModel?.schoolProfileViewModel?.address?.addressline1">
                            {{homeViewModel?.schoolProfileViewModel?.address?.addressline1}}<br />
                        </a>
                        <a *ngIf="homeViewModel?.schoolProfileViewModel?.address?.addressline2">
                            {{homeViewModel?.schoolProfileViewModel?.address?.addressline2}}<br />
                        </a>
                        <a
                            *ngIf="homeViewModel?.schoolProfileViewModel?.address?.city || homeViewModel?.schoolProfileViewModel?.address?.state">
                            {{homeViewModel?.schoolProfileViewModel?.address?.city}}
                            {{homeViewModel?.schoolProfileViewModel?.address?.state}}
                            <br />
                        </a>
                        <a>
                            {{homeViewModel?.schoolProfileViewModel?.address?.postalcode}}
                        </a>
                    </div>
                    <div class="col-md-3 col-xs-6 md-margin-b-30">
                        <h4>Phone</h4>
                        <!-- <a>{{homeViewModel?.schoolProfileViewModel.schoolProfile?.phone | ejPhoneNumber}}</a> -->
                        <a>+971507281550</a>
                    </div>
                    <div class="col-md-3 col-xs-6">
                        <h4>Email</h4>
                        <!--  <a href="mailto:#">{{homeViewModel?.schoolProfileViewModel.schoolProfile?.email}}</a> -->
                        <a>mindvaultbrainacs@gmail.com</a>
                    </div>
                    <div class="col-md-3 col-xs-6">
                        <h4>Web</h4>
                        <a>{{homeViewModel?.schoolProfileViewModel.schoolProfile?.url}}</a>
                    </div>
                </div>

                <!--// end row -->
            </div>
            <div class="section-seperator">
                <div class="content-md container">
                    <div class="row margin-b-40">
                        <div class="col-sm-6">
                            <h2>Send Us A Note</h2>
                            <p>Please fill out the form below with the query and we'll get back to you</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-5 col-sm-7">
                            <form id="enquiryForm" name="enquiryForm" (ngSubmit)="f.form.valid && saveEnquiryEvent(f)"
                                #f="ngForm" novalidate>
                                <div class="margin-b-10">
                                    <input type="text" class="form-control kt-input" placeholder="Name" id="firstName"
                                        name="firstName" maxlength="50" autocomplete="off" #firstName="ngModel" required
                                        [(ngModel)]="enquiryModel.firstName"
                                        [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }">
                                    <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                        <div *ngIf="firstName.errors?.required">Name is required</div>
                                    </div>
                                </div>
                                <div class="margin-b-10">
                                    <input type="email" class="form-control kt-input" placeholder="Email" id="email"
                                        name="email" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
                                        maxlength="100" #email="ngModel" autocomplete="off"
                                        [(ngModel)]="enquiryModel.email" required
                                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                                    <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                        <div *ngIf="email.errors?.required">Email is required</div>
                                        <div *ngIf="email.errors?.pattern">Invalid email format</div>
                                    </div>
                                </div>
                                <div class="margin-b-10">
                                    <ej-phone-number *ngIf="!loadingCountryCodes && !loading"
                                        [inputPhoneNumber]="enquiryModel.mobile" [displayLabel]="false"
                                        (phoneNumberChanged)="receivePhoneNumber($event)"
                                        (phoneNumberError)="receivePhoneNumberError($event)">
                                    </ej-phone-number>
                                    <div *ngIf="f.submitted && (phoneNumberError || phoneCountryCodeError)"
                                        class="invalid-feedback displayBlock">
                                        <div *ngIf="phoneNumberError">Phone is required</div>
                                        <div *ngIf="phoneCountryCodeError && !phoneNumberError">Country Code is required
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="margin-b-10">
                                    <input type="text" class="form-control" placeholder="Mobile" id="mobile"
                                        name="mobile" autocomplete="off" maxlength="15" pattern=".{8,15}"
                                        #mobile="ngModel" [(ngModel)]="enquiryModel.mobile" required
                                        [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }">
                                    <div *ngIf="f.submitted && mobile.invalid" class="invalid-feedback">
                                        <div *ngIf="mobile.errors?.required">Mobile is required</div>
                                        <div *ngIf="mobile.errors?.pattern">Invalid mobile number</div>
                                    </div>
                                </div> -->
                                <div class="margin-b-20">
                                    <textarea class="form-control" rows="5" placeholder="Message" id="message"
                                        autocomplete="off" name="message" maxlength="1000" #message="ngModel"
                                        [(ngModel)]="enquiryModel.message" required
                                        [ngClass]="{ 'is-invalid': f.submitted && message.invalid }">
                                        </textarea>
                                    <div *ngIf="f.submitted && message.invalid" class="invalid-feedback">
                                        <div *ngIf="message.errors?.required">Message is required</div>
                                    </div>
                                </div>
                                <div class="form-group margin-b-20"
                                    [ngClass]="{ 'is-invalid': f.submitted && errorRecaptchaRender }">
                                    <re-captcha (resolved)="resolved($event)" #recaptchaRef="reCaptcha"
                                        siteKey={{captchaSiteKey}}>
                                    </re-captcha>
                                    <div *ngIf="f.submitted && errorRecaptchaRender" class="invalid-feedback">
                                        <div *ngIf="errorRecaptchaRender">Please verify that you are not a robot.</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn-theme btn-theme-sm btn-base-bg text-uppercase"
                                    [disabled]="saveEnquiryBtnDisabled">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Contact -->
    <!--========== END PAGE LAYOUT ==========-->
    <!--========== FOOTER ==========-->
    <footer class="footer">
        <div class="content container">
            <div class="row">
                <div class="col-xs-6">
                    <img class="footer-logo" src="assets/media/logos/ej_logo.png" alt="eduJournal"
                        style="width: 140px;">
                </div>
                <div class="col-xs-6 text-right sm-text-left">
                    <p class="margin-b-0"><a class="fweight-400" href="http://edujournal.com">www.edujournal.com</a></p>
                </div>
            </div>
            <!--// end row -->
        </div>
    </footer>
    <!--========== END FOOTER ==========-->
    <!-- Back To Top -->
    <div id="kt_scrolltop" class="kt-scrolltop">
        <i class="fa fa-arrow-up"></i>
    </div>
    <a href="javascript:void(0);" class="js-back-to-top back-to-top">Top</a>

    <!-- JAVASCRIPTS(Load javascripts at bottom, this will reduce page load time) -->
    <!-- CORE PLUGINS -->
    <script src="assets/vendors/general/jquery/dist/jquery.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery-migrate.min.js" type="text/javascript"></script>
    <script src="assets/vendors/general/bootstrap/dist/js/bootstrap.min.js" type="text/javascript"></script>

    <!-- PAGE LEVEL PLUGINS -->
    <script src="assets/vendors/wp-js/jquery.easing.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.back-to-top.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.smooth-scroll.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.wow.min.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.parallax.min.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/jquery.appear.js" type="text/javascript"></script>
    <script src="assets/vendors/general/swiper/js/swiper.jquery.min.js" type="text/javascript"></script>

    <!-- PAGE LEVEL SCRIPTS -->
    <script src="assets/vendors/wp-js/layout.min.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/components/progress-bar.min.js" type="text/javascript"></script>
    <script src="assets/vendors/general/swiper/js/swiper.min.js" type="text/javascript"></script>
    <script src="assets/vendors/wp-js/components/wow.min.js" type="text/javascript"></script>
    <script src="https://www.google.com/recaptcha/api.js" async defer></script>

</body>
<!-- END BODY -->

</html>


<!-- <div id="kt_header" class="kt-header kt-grid__item  kt-header--fixed " data-ktheader-minimize="on">
    <div class="kt-header__top">
        <div class="kt-container">
            <div class="kt-header__brand   kt-grid__item">
                <div class="kt-header__brand-logo">
                    <a>
                        <img alt="Logo" src="./assets/media/logos/ej_logo.png" class="kt-header__brand-logo-default">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-header__topbar kt-grid__item kt-grid__item--fluid">
        <div class="kt-header__topbar-item kt-header__topbar-item--user">
            <div class="kt-header__topbar-wrapper" data-offset="10px,0px">
                <a *ngIf="!isLoggedIn" (click)="login()">Login</a>
                <a *ngIf="isLoggedIn" (click)="gotoApplication()">Go to Application</a>
            </div>
        </div>
    </div>

</div>
<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div class="kt-content kt-grid__item kt-grid__item--fluid">
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <div class="">
                            <p>Tenant Home Page</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->